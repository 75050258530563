import React, { useEffect, useState, useRef } from 'react';
import { FiBell, FiSettings } from "react-icons/fi";
import { FaLocationDot } from "react-icons/fa6";
import { BiLogOut } from "react-icons/bi";
import '../styles/Header.css';
import { useBranch } from './BranchProvider';
import { getAllBranches } from '../services/productService';
import { showExitSession } from './Alert'; 

const Header = ({ selectedItem, onLogout }) => {
  const { selectedBranchId, setSelectedBranchId } = useBranch();
  const [branches, setBranches] = useState([]);
  const [menuOpen, setMenuOpen] = useState(false);
  const [logoutMenuOpen, setLogoutMenuOpen] = useState(false);
  const menuRef = useRef(null);
  const logoutMenuRef = useRef(null);

  useEffect(() => {
    const fetchBranches = async () => {
      try {
        const response = await getAllBranches();
        setBranches(response);
      } catch (error) {
        console.error('Error fetching branches:', error);
      }
    };
    fetchBranches();
  }, []);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (menuRef.current && !menuRef.current.contains(event.target)) {
        setMenuOpen(false);
      }
      if (logoutMenuRef.current && !logoutMenuRef.current.contains(event.target)) {
        setLogoutMenuOpen(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleBranchChange = (branchId) => {
    setSelectedBranchId(branchId);
    setMenuOpen(false); 
    console.log('Selected Branch ID:', branchId);
  };

  const handleLogout = () => {
    showExitSession(() => {
      onLogout(); 
      setLogoutMenuOpen(false); 
  });
      
    
  };

  const selectedBranch = branches.find(branch => branch.id === selectedBranchId);

  return (
    <div className='content--header'>
      <h1 className='header--title'>{selectedItem}</h1>
      <div className='header--icons'>
        <div className='branch-selector' ref={menuRef}>
          <div onClick={() => setMenuOpen(!menuOpen)} className='branch-selector__current'>
            {selectedBranch ? selectedBranch.name : 'Seleccione una sucursal'}
            <FaLocationDot className='icon' />
            <span className='branch-selector__icon'></span>
          </div>
          {menuOpen && (
            <div className='branch-selector__menu'>
              {branches.map((branch) => (
                <div
                  key={branch.id}
                  onClick={() => handleBranchChange(branch.id)}
                  className='branch-selector__item'
                >
                  {branch.name}
                </div>
              ))}
            </div>
          )}
        </div>
        <FiBell className='icon' />

        <div className='logout-menu' ref={logoutMenuRef}>
          <FiSettings className='icon' onClick={() => setLogoutMenuOpen(!logoutMenuOpen)} />
          {logoutMenuOpen && (
            <div className='logout-menu__content' onClick={handleLogout}>
              <BiLogOut className='logout-menu__icon' />
              <span className='logout-menu__text'>Cerrar Sesión</span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default Header;
